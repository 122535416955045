<template>
  <v-row class="item-bio-panel">
    <!-- item profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">Details</h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Status :</span>
              <v-chip
                small
                class="font-weight-semibold text-capitalize text-center"
                :color="resolveCodeStatusVariant(itemData.status)"
              >
                {{
                  itemData.status
                }}
              </v-chip>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">ID :</span>
              <span class="text--secondary"> PC{{ itemData.id }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Promocode Title :</span>
              <span class="text--secondary">{{ itemData.title }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2"> Description EN :</span>
              <span class="text--secondary">{{ itemData.description_en }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2"> Description AR :</span>
              <span class="text--secondary">{{ itemData.description_ar }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2"> Promocode Type :</span>
              <span class="text--secondary">{{ itemData.promocode_type }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2"> Covered Service:</span>
              <span class="text--secondary">{{ itemData.covered_services }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2"> Discount Value:</span>
              <span class="text--secondary">{{ itemData.offer }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2"> Beneficiaries:</span>
              <span class="text--secondary">{{ itemData.beneficiaries }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2"> Minimum Purchase Value:</span>
              <span class="text--secondary">{{ itemData.minimum_purchase_value }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2"> Maximum Applied Price:</span>
              <span class="text--secondary">{{ itemData.maximum_applied_value }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2"> Usage Limit:</span>
              <span class="text--secondary">{{ itemData.usage_limit }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2"> Usage Limits per User:</span>
              <span class="text--secondary">{{ itemData.usage_limits_per_user }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Start Date:</span>
              <span class="text--secondary">{{ moment(itemData.start_date) }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">End Date:</span>
              <span class="text--secondary">{{ moment(itemData.end_date) }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2" v-if="is_history_view">
              <span class="font-weight-medium me-2">Number of orders using this promocode:</span>
              <span class="text--secondary">{{ itemData.numberOfOrdersUsing }}</span>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiBriefcaseVariantOutline, mdiCheck, mdiCheckboxBlankCircle } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import moment from 'moment'
import useCodesList from './../../codes-list/useCodesList'
import router from '@/router'

export default {
  props: {
    itemData: {
      type: Object,
      required: true,
    },
    isPlanUpgradeDialogVisible: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('refresh', value)
    },
    moment: date => {
      return moment(date).locale('JO').format('YYYY-MM-DD [@]  HH:MM')
    },
  },

  setup() {
    const { resolveCodeStatusVariant } = useCodesList()

    const isBioDialogOpen = ref(false)
    const is_history_view = ref(0)

    onMounted(() => {
      is_history_view.value = router.currentRoute.params.history ? 1 : 0
    })
    return {
      resolveCodeStatusVariant,
      isBioDialogOpen,
      is_history_view,
      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
      },
    }
  },
}
</script>
