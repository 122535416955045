import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useCodesList() {
  const codesListTable = ref([])

  const tableColumns = [
    { text: 'ID', value: 'id', sortable: false },
    { text: 'Promo Code Title', value: 'title', sortable: false },
    { text: 'Promo Code Type', value: 'promocode_type', sortable: false },
    { text: 'Discount value', value: 'offer', sortable: false },
    { text: 'Start Date @ Time', value: 'start_date', sortable: false },
    { text: 'End Date @ Time', value: 'end_date', sortable: false },
    { text: 'status', value: 'status', sortable: false, align: 'center' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const planFilter = ref(null)
  const totalCodesListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
    q: searchQuery.value,
  })
  const codesTotalLocal = ref([])
  const selectedRows = ref([])

  // fetch data
  const fetchCodes = () => {
    let params = {
      filter: 'active',
      options: options.value,
    }

    params.page = options.value.page

    if (planFilter.value) {
      if (planFilter.value == 'Title') params.title = searchQuery.value
      if (planFilter.value == 'Date') params.start_date = searchQuery.value
    }

    store
      .dispatch('app-codes/fetchCodes', params)
      .then(response => {
        const data = response.data.data
        codesListTable.value = data.data
        totalCodesListTable.value = data.total
        codesTotalLocal.value = data.total

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.error(error)
      })
  }

  const resolveCodeStatusVariant = status => {
    if (status === 1 || status === 'Enabled') return 'success'
    if (status === 1 || status === 'Disabled') return 'warning'
    if (status === 2 || status === 'Expired') return 'warning'

    return 'primary'
  }

  watch([searchQuery, planFilter, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchCodes()
  })

  return {
    resolveCodeStatusVariant,
    codesListTable,
    planFilter,
    searchQuery,
    tableColumns,
    totalCodesListTable,
    loading,
    options,
    codesTotalLocal,
    selectedRows,
    fetchCodes,
  }
}
